import FiveGridLine_DashedLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/DashedLine/DashedLine.skin';


const FiveGridLine_DashedLine = {
  component: FiveGridLine_DashedLineComponent
};


export const components = {
  ['FiveGridLine_DashedLine']: FiveGridLine_DashedLine
};

